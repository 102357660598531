import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface IEmailTemplate {
    key: string;
    subject: string;
    body: string;
    isHtml: boolean;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
}

export class EmailTemplate implements IEmailTemplate {

    constructor(data?: IEmailTemplate) {
        if (data) this.update(data);
    }

    update(data: IEmailTemplate) {
        mapData(data, { root: () => this });
    }

    key: string = "";
    subject: string = "";
    body: string = "";
    isHtml: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;

    isNew: boolean = true;
}


