
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import { required, email } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import * as toastr from "toastr";
    import apiClient from "@/stuff/ApiClient";
    import { mapData } from "@/stuff/DataMapping";
    import { EmailTemplate, IEmailTemplate } from "@/model/EmailTemplate";
    import utils from "@/stuff/Utils";
    import Editor from "@tinymce/tinymce-vue";

    @Component({
        components: { ApiButton, Editor }
    })
    export default class EmailTemplates extends Vue {

        mounted() {
            document.addEventListener('focusin', (e: Event) => {
                if (e.target && (e.target as HTMLElement).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
                    e.stopImmediatePropagation();
                }
            });

            this.search();
        }

        //
        // -- properties
        //

        emailTemplateList: Array<IEmailTemplate> = [];
        readonly emailTemplate = new EmailTemplate();

        // computed
        get dialogueTitle(): string {
            if (!this.emailTemplate) return "- - -";
            if (this.emailTemplate.isNew) return "New Email Template";
            return "Edit " + this.emailTemplate.key;
        }

        tinyMceKey = "";
        tinyMceConfig = {
            height: 400,
            statusbar: false,            
            paste_data_images: true,
            plugins: [ 'lists image link table' ],
            menubar: "format insert table help",
            toolbar1: 'undo redo | fontselect fontsizeselect formatselect',
            toolbar2: "bold underline italic strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist",
            automatic_uploads: false,
            images_upload_handler: (blobInfo: any) => {
                return new Promise((resolve) => {
                    resolve("data:image/png;base64," + blobInfo.base64());
                })
            }
        }

        //
        // -- methods
        //

        async fetchTinyMceKey() {
            const response: string = await apiClient.get("api/emailTemplate/tinyMceKey");
            this.tinyMceKey = response;
        }

        async edit(emailTemplateData: IEmailTemplate) {
            if (utils.isEmptyOrWhitespace(this.tinyMceKey)) {
                await this.fetchTinyMceKey();
            }

            this.emailTemplate.update(emailTemplateData);
            this.$v.$reset();
            const serverEmailTemplateData = await apiClient.get(`api/emailTemplate/Load?key=${emailTemplateData.key}`);
            this.emailTemplate.update(serverEmailTemplateData);
            this.showEditDlg();
        }

        async editNew() {
            if (utils.isEmptyOrWhitespace(this.tinyMceKey)) {
                await this.fetchTinyMceKey();
            }
            
            utils.resetObject(this.emailTemplate);
            this.$v.$reset();
            this.showEditDlg();
        }

        showEditDlg() {
            const html = this.emailTemplate.isHtml;
            this.emailTemplate.isHtml = false;
            this.$bvModal.show("emailTemplateDialogue");
            window.setTimeout(() => {
                this.emailTemplate.isHtml = html;
            }, 100);
        }

        cancel() {
            this.$bvModal.hide("emailTemplateDialogue");
            utils.resetObject(this.emailTemplate);
        }

        async save(event: Event) {
            this.$v.$touch();
            if (this.$v.emailTemplate.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            await apiClient.post("/api/emailTemplate/save", this.emailTemplate, event);
            toastr.success("Saved");           
            this.$bvModal.hide("emailTemplateDialogue");
            this.search();
        }

        async deleteItem(event: Event) {
            if (this.emailTemplate.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.emailTemplate.key + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/emailTemplate/delete", this.emailTemplate, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("emailTemplateDialogue");
            this.search();
        }

        private async search(event?: Event) {
            const responseData = await apiClient.get("/Api/EmailTemplate/Search", event);
            this.emailTemplateList = mapData<Array<EmailTemplate>>(responseData, { root: (e) => new EmailTemplate(e) });
        }

        @Validations()
        validations() {
            const validations = {
                emailTemplate: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.emailTemplate.key = { required };
            validations.emailTemplate.subject = { required };
            validations.emailTemplate.body = { required };
            return validations;
        }
    }
